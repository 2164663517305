import { Audience, Platform, Universe, ValidatedBatchResult } from "../models";
import { Subject } from "rxjs";
import { ViewModel } from "./view-model.viewmodel";
import { TopicsViewModel } from "./topics.viewmodel";
import { AudienceStatusEnum, CappingLevel } from "../enums";

/**
 * Represents a audience in RADR
 *
 * @export
 * @class AudienceViewModel
 */
export class AudienceViewModel extends Audience implements ViewModel {
  public isDirty: boolean = false;

  constructor(audience: any = {}) {
    super();
    Object.assign(this, audience);

    this.isDirty = false;

    return new Proxy(this, {
      set: (target: any, property: string, value: any): boolean => {
        target[property] = value;
        return true;
      }
    });
  }

  get canEdit(): boolean {
    return true;
  }

  public cappedControlCount: number;
  public cappedTestCount: number;
  public validatedBatchValues: { [key: string]: ValidatedBatchResult };
  public platforms: Platform[];
  public audienceType: number;
  public autoCalculateCounts: boolean;
  public isCappingActive: boolean;
  public isControlActive: boolean;
  public cappingLevel: CappingLevel;
  public capFixed: number;
  public capPercentage: number;
  public controlPercentage: number;
  public lookBackType: string;
  public dynamicLookBackPeriodType: string;
  public dynamicLookBackPeriod: number;
  public lookBackStartDate: string;
  public lookBackEndDate: string;
  public dwellTimeMinutes: number;
  public onPropertyChange$: Subject<string>;
  public status: AudienceStatusEnum;
  public topics?: TopicsViewModel[] = [];
  public audiences?: AudienceViewModel[] = [];
  public universes?: Universe[] = [];
  public dataType: string;
  public subscriberCap: number;
  public hhCount: number;

  public get qualifierIds(): number[] {
    return Array.from(this.getQualifiersFromRuleSet(this.definition.rules).values());
  }

  private getQualifiersFromRuleSet(rules: any[]): Set<number> {
    const qualifiers: Set<number> = new Set();

    rules.forEach(rule => {
      if (rule.rules) {
        this.getQualifiersFromRuleSet(rule.rules).forEach(qualifierId => {
          qualifiers.add(qualifierId);
        });
      } else {
        qualifiers.add(rule.field);
      }
    });

    return qualifiers;
  }

  public removeIsControlFromRules(rules: any[]): any[] {
    rules.forEach(rule => {
      if (rule.rules) {
        this.removeIsControlFromRules(rule.rules).forEach(r => {
          r.isControl = false;
        });
      } else {
        rule.isControl = false;
      }
    });
    return rules;
  }

  public hasIsControlQualifiers(rules: any[]): boolean {
    return !!rules.find(rule => {
      if (rule.rules) {
        return this.hasIsControlQualifiers(rule.rules);
      } else {
        return rule.isControl;
      }
    });
  }
}
